import { Link , useParams} from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { useEffect, useState } from "react";
import { rating, userRatingAdd } from "../../utils/apiUrlsSetup";
import {Header, Footer} from "../shared-components"

const Rating = (params) => {
  const {id} = useParams()
  const userDetails =  JSON.parse(localStorage.getItem("userDetails")) || {} ;
  const [ratingAnswer, setRatingAnswer] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const ratingChanged = async(newRating) => {
    setShowPopup(false);
    const response = await rating({})
          if (response.ErrorCode === 0){
              const customerRating = response.Response[newRating - 1].id
                if(customerRating === newRating){
                    setRatingAnswer(response.Response[newRating - 1]);
                    const formData = new FormData()
                    formData.append("order_id", id)
                    formData.append("emoji_rating", newRating)
                    
                    const data = await userRatingAdd(formData)
                    if(data.ErrorCode === 0){
                        setShowPopup(true);
                    }

                }
          }
      }
   
  return (
    <>
    <Header userDetails={userDetails} />
      <div className="px-4 mx-4 pt-5 mx-lg-auto lg:w-1/2 md:mx-auto shadow rating_page rounded">
        <div className="mx-2">
          <h3 className="text-2xl font-bold text-center pt-4 flex-1 font_heading">Rating</h3>
        </div>
        <div className="bg-white p-4 pt-0 px-6 relative">
          <h3 className="text-xl font-bold text-center pt-4 pb-2 flex-1">
            Please rate your experience here.
          </h3>
          <div className="flex justify-center w-full">
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={50}
              activeColor="#ffd700"
              classNames="rating-component justify-around flex"
            />
          </div>
          <p className="text-center font_basic">{ratingAnswer["title"]}</p>
          {showPopup ? (
            <div className="text-center flex justify-center mt-2">
              <img
                className="animate__animated animate__zoomIn w-1/4"
                src={ratingAnswer["icon"]}
              />
            </div>
          ) : null}
        </div>
        <div className="d-flex justify-content-center pb-3">
        <a 
              href="/"
               className="back_btn text-decoration-underline text-success font_13"
              >Go Back to home page
        </a>
        </div>
      </div>
      <div className="px-3 d-none d-md-block mb-5 pb-2 mb-lg-0 pd-lg-0">
        <Footer />
      </div>
      <style jsx>
            {`
            .rating_page{
                margin-top: 120px !important;
            }
            @media screen and (max-width:900px){
                .review_page{
                  margin-top: 180px !important;
                }
            }
            `}
      </style>
    </>
  );
};

export default Rating;
