import { Header, Footer } from "../shared-components";
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./review.css"
import { AddUserFeedback, ReviewQues } from "../../utils/apiUrlsSetup"

const Review = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [showQuestion, setShowQuestion] = useState(true);
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};
    const authToken = localStorage.getItem("authToken") || "";
    const [ReviewDetails, setReviewDetails] = useState([]);
    const [counter, setCounter] = useState(0);
    const [category_check_list, setCategoryCheckList] = useState([]);
    const [givenfeedback, setGivenFeedback] = useState(true);
    const [reviewAnswer, setReviewAnswer] = useState({
        question_id: null,
        question_type_id: null,
        event: null,
    });

    useEffect(() => {
        if (authToken) {
            ReviewQuestions();
        }
    }, []);

    const ReviewQuestions = async () => {
        const data = { "id": id }
        let response = await ReviewQues(data)

        if (response.ErrorCode === 0) {
            setReviewDetails(response.Response);
        }
    };

    const handleInputChange = (e, question_id, question_type_id) => {

        if (e?.target?.checked === true) {
            setCategoryCheckList([...category_check_list, e.target.value]);
        } else {
            const check_list = [];
            category_check_list.map((check) => {
                if (check != e.target.value) {
                    check_list.push(check);
                }
            });
            setCategoryCheckList(check_list);
        }

        setReviewAnswer({
            question_id: question_id,
            question_type_id: question_type_id,
            event: e?.target?.value,
        });


    };


    const handleNext = async () => {


        if (reviewAnswer?.question_type_id === 2) {
            category_check_list.shift();
        }
        let obj = {
            question_id: reviewAnswer?.question_id,
            question_type_id: reviewAnswer?.question_type_id,
            answer:
                reviewAnswer?.question_type_id === 2
                    ? category_check_list
                    : reviewAnswer?.event,
            order_id: id,
        };
        setShowQuestion(false);
        const response = await AddUserFeedback(obj)
        if (response.ErrorCode === 0) {

            setReviewAnswer({
                question_id: null,
                question_type_id: null,
                event: null,
            });
            setShowQuestion(true);

            if (counter >= ReviewDetails.length - 1) {
                setGivenFeedback(false);
                setCounter(counter);
                navigate(
                    {
                        pathname: `/rating/${id}`,
                        state: {
                            orderId: `${id}`,
                        },
                    }

                )
            } else {

                setCounter(counter + 1);
            }
        }

    };

    return (<>
        <Header
            userDetails={userDetails}
        />
        <div className="px-4 mx-4 mx-lg-auto review_page lg:w-1/2 my-0 md:mx-auto shadow rounded py-3">
            <div className="flex mx-2">
                <h3 className="text-center py-4 flex-1 font_heading m-0">Review</h3>
            </div>
            <div className="bg-white p-4 px-6">
                {givenfeedback ? (
                    <div className="wrapper">
                        <div className="order-quantity flex justify-between">
                            <div className="left w-full">
                                <div className="order-item flex flex-col">
                                    <>
                                        {showQuestion ? <>
                                            <label className="fs-5 font_paragraph"> {ReviewDetails[counter]?.question}</label>
                                            <div className=" my-3 flex justify-between flex-col md:flex-row">
                                                {ReviewDetails[counter]?.["question_type_id"] === 1 ? (
                                                    <>
                                                        {ReviewDetails[counter]?.["question_option"].map(
                                                            (x, index) => (

                                                                <div className="w-100 d-flex">

                                                                    <div className="radio">
                                                                        <input
                                                                            id={`radio${ReviewDetails[counter]?.q_id}-${index + 1}`}
                                                                            name={`radio${ReviewDetails[counter]?.q_id}`}
                                                                            type="radio"
                                                                            checked={reviewAnswer.event === x["options"]}
                                                                            value={x["options"]}

                                                                            className="cursor-pointer"

                                                                            onChange={(e) => {

                                                                                handleInputChange(
                                                                                    e,
                                                                                    ReviewDetails[counter]?.q_id,
                                                                                    ReviewDetails[counter]
                                                                                        ?.question_type_id
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label
                                                                            htmlFor={`radio${ReviewDetails[counter]?.q_id}-${index + 1}`}
                                                                            className="radio-label cursor-pointer ms-3"
                                                                        >
                                                                            {" "}
                                                                            {x["options"]}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </>
                                                ) : null}
                                                {ReviewDetails[counter]?.["question_type_id"] === 2 ? (
                                                    <>
                                                        {ReviewDetails[counter]?.["question_option"].map(
                                                            (x, index) => (
                                                                <div className="flex">
                                                                    <div className="checkbox">
                                                                        <input
                                                                            id={`checkbox-${index + 1}`}
                                                                            name="checkbox"
                                                                            type="checkbox"
                                                                            value={x["options"]}
                                                                            className="cursor-pointer"
                                                                            onChange={(e) => {
                                                                                handleInputChange(
                                                                                    e,
                                                                                    ReviewDetails[counter]?.q_id,
                                                                                    ReviewDetails[counter]
                                                                                        ?.question_type_id
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label
                                                                            htmlFor={`checbox-${index + 1}`}
                                                                            className="checkbox-label cursor-pointer ms-2"
                                                                        >
                                                                            {" "}
                                                                            {x["options"]}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </>
                                                ) : null}
                                                {ReviewDetails[counter]?.["question_type_id"] === 3 ? (
                                                    <>
                                                        <textarea
                                                            rows={4}
                                                            onChange={(e) => {
                                                                handleInputChange(
                                                                    e,
                                                                    ReviewDetails[counter]?.q_id,
                                                                    ReviewDetails[counter]?.question_type_id
                                                                );
                                                            }}
                                                            className="w-full  p-4 bg-white rounded shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                                        ></textarea>
                                                    </>
                                                ) : null}
                                            </div>
                                        </>
                                            : null}
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>) : null}
                <div className="text-end">
                    <button
                        onClick={handleNext}
                        className={` ${!reviewAnswer?.event ? "bg_disable" : "bg_green"
                            } rounded text-white cursor-pointer font_basic px-5 py-3`}
                        disabled={!reviewAnswer?.event}
                    >
                        {counter === ReviewDetails.length - 1 ? "Submit" : "Next"}
                    </button>
                </div>
            </div>
        </div>
        <div className="px-3 d-none d-md-block mb-5 pb-2 mb-lg-0 pd-lg-0">
            <Footer />
        </div>
    </>)
}

export default Review;

{/* {console.log(counter)} */ }
{/* {ReviewDetails[counter]?.["question_type_id"] === 4 ? (
    <select
        onChange={(e) => {
            handleInputChange(
                e,
                ReviewDetails[counter]?.q_id,
                ReviewDetails[counter]?.question_type_id
            );
        }}
        className="my-3 w-full bg-white  placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
    >
        <option value="select">Select</option>
        {ReviewDetails[counter]?.["question_option"].map(
            (x) => (
                <>
                    <option value={x["options"]}>
                        {x["options"]}
                    </option>
                </>
            )
        )}
    </select>
) : null} */}