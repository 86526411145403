import React,{useEffect, useState} from "react";
import {
  Header,
  Footer,
  SingleSlider,
  ProductSlider,
  Banner,
  HeaderBanner,
} from "./shared-components";
import { useNavigate } from "react-router-dom";
import AddressSuggestionInput from "./shared-components/addressSuggestionInput/addressSuggestionInput"
import CategoriesCard from "./shared-components/categoriesCard/categoriesCard";
import Loader from "./shared-components/loader/loader"
import CategoryHeaderList from "./shared-components/categoryHeaderList/categoryHeaderList";

import {
  carouselData,
  categoryData,
  cartItemsCount,
  tempCartItemsCount,
  recentOrders,
  pincodeBrand
} from "../utils/apiUrlsSetup";


const Home = () => {
  const navigate = useNavigate()
  const [carouselList, setCarouselList] = useState([])
  const [showLoader,setShowLoader] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0)
  const [badgePrice, setBadgePrice] = useState(0)
  const [productSuggestion, setProductSuggestion] = useState(false)
  const [recentOrderList, setRecentOrderList] = useState([])
  const [brandData, setBrandData] = useState([])
  const [screenWidth,setScreenWidth] = useState(window?.screen?.width);
  
  
  const authToken = localStorage.getItem("authToken") || '' ;
  const pincode = localStorage.getItem("pincode") || '' ;
  const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {}
  const [cartSession, setCartSession] = useState( localStorage.getItem("cart-session") || "" )
  
  const loadCarousalData = async () =>{
    setShowLoader(true)
    let response = await carouselData({"pincode":pincode, page: 1})
    
    let items = [];
    if(response && response.ErrorCode === 0){
      let lastPage = response.Response.carousels_list.last_page;
      items.push(...response.Response.carousels_list.data)
      setCarouselList(items)
      for (let i = 2; i < lastPage+1; i++) {
        let response = await carouselData({"pincode":pincode,page: i})
        if(response && response.ErrorCode === 0){
          items.push(...response.Response.carousels_list.data)
          setCarouselList(items)
        }
      }
      getBrands()
      setShowLoader(false)
    }
  }
  
  useEffect(()=>{
    getCategoryDataList()
    window.scrollTo(0,0)
  },[])
  
  
  useEffect(()=>{
    loadCarousalData()
  },[])
  
  const getBrands = async()=>{
    setShowLoader(true)
    const brandsRes = await pincodeBrand({})
    setBrandData(brandsRes?.Response)  
  }
  
  useEffect(()=>{
    getCategoryData()
    getBrands()
    if(authToken){
      getRecentOrders()
    }
  },[])
  
  const getCarouselData = async()=>{
    setShowLoader(true)
    let response = await carouselData({"pincode":pincode, page: 1})
    if(response && response.ErrorCode === 0){
      setCarouselList(response.Response.carousels_list.data)
      setShowLoader(false)
    }
    
  }
  
  
  const getCategoryData = async()=>{
    setShowLoader(true)
    let response = await categoryData({"pincode":pincode})
    if(response && response.ErrorCode === 0){
      setCategoryListData(response.Response.category)
      setShowLoader(false)
    }
  }
  
  const getCartItemsCount = async() =>{
    if(authToken)
    {
      const res = await cartItemsCount({})
      if(res.ErrorCode === 0){
          setBadgeCount(res?.Data?.total_count)
          setBadgePrice(res?.Data?.total_price)
      }
    }
    else if(cartSession)
    {
      const res = await tempCartItemsCount({})
      if(res.ErrorCode === 0){
          setBadgeCount(res?.Data?.total_count)
          setBadgePrice(res?.Data?.total_price)
      }

    }
  }
  
  const getRecentOrders = async()=>{
    const response = await recentOrders({});
    if(response?.ErrorCode === 0 ){
      setRecentOrderList(
        [
          {
            type : "product_carousel",
            carousel_name: "Recent Orders",
            items : response.Response
          }
    ]
    )
  }
} 

useEffect(()=>{
  window.addEventListener('resize',onResize)
  
  return ()=>{
    window.removeEventListener('resize', onResize);
  }
},[])

const onResize = (e)=>{
  setScreenWidth( window.screen.width)
}

const onCartEmpty = () =>{
  setCarouselList([])
  loadCarousalData()
}
const [subHeaderData, setSubHeaderData] = useState([]);
const [moreData, setMoreData] = useState([]);
const [categoryId, setCategoryId] = useState()
const [categoryListData, setCategoryListData] = useState([])

const getCategoryDataList = async () => {
  let response = await categoryData({"pincode":pincode})
  if ( response && response.ErrorCode === 0) {
    setCategoryListData(response.Response.category)
      const copyCategoryListData = [...response.Response.category]
      const moreData = copyCategoryListData.splice(8, 50)
      setSubHeaderData(copyCategoryListData)
      setMoreData(moreData)
  }
}


  return (
    <div className="home_page mb-5 mb-lg-0 pb-lg-0">
      {showLoader ? <Loader bottom="50%" right="47%" /> : null}
      <Header
        userDetails={userDetails}
        getCarouselData={getCarouselData} 
        getRecentOrders = {getRecentOrders}
        loadCarousalData = {loadCarousalData}
        badgeCount={badgeCount}
        badgePrice={badgePrice}
        productSuggestion={productSuggestion}
        setProductSuggestion={setProductSuggestion}
        onCartEmpty={onCartEmpty}
      />
      {/* <div className="pb-5">
        <CategoryHeaderList
          categoryHeaderData={subHeaderData}
          moreData={moreData}
          setCategoryId={setCategoryId}
          categoryId={categoryId}
        />
      </div> */}
      <div className="full-width-slider homepage">
      <SingleSlider/>        
      </div>

      <div className="wrapper">
        {/* {authToken && <HeaderBanner />} */}
        {/* <HeaderBanner/> */}

        <CategoriesCard 
          categoryList={categoryListData}
        />

      {/* Brand List       */}
      {/* {
          !!brandData.length && 

          <div className="d-flex flex-column " >
            <h4 className="title py-4 font_heading">
              Brands
              <span className="see-all-btn float-right font_basic"
                onClick={() => { navigate(`/brands`, { state: brandData }) }}
                >
                See All
              </span>
            </h4>
            <div className="d-flex overflow-hidden w-100">
            {
              brandData.slice(0, parseInt(screenWidth/60-2)).map((value, index) => {
             return <div className=" text-center brand_card_parent mt-3 cursor-pointer" 
                        key={index}
                        onClick={() => { navigate(`/brand/${value.name}/${value.id}`) }}
                    >
                    <div className="brand_card_image border rounded ms-1">
                        <img src={value.product_image ? value.product_image : null} alt="" />
                    </div>
                    <p className="m-0 text-center mt-2 font_500">{value.name}</p>
                </div>
            })
            }
            </div>
          </div>

        } */}

        {authToken && 
        <div className="m-0">
          <ProductSlider 
          listData={recentOrderList} 
          setListData={setRecentOrderList}  
          updateListData={getRecentOrders} 
          getCartItemsCount={getCartItemsCount}
        />
        </div>}

       
        
        <ProductSlider 
          listData={carouselList} 
          setListData={setCarouselList}  
          updateListData={getCarouselData} 
          getCartItemsCount={getCartItemsCount}
        />
        {authToken && 
        <div className="suggestion_product_container py-5">
          <div className="text_heading text-center">
            <p className="m-0 text-secondary fs-1 fw-bold font_family_common text-center">didn't find what you were looking for?</p>
            <p className="m-0 text-secondary font_13 font_family_common mb-4">Suggest something & we'll look into it.</p>
          <button 
            type="button" 
            className="py-2 px-3 bg_green rounded text-white mb-3"
            onClick={()=>{setProductSuggestion(true)}}  
          >
            Suggest a product
          </button>
          </div>
        </div>}
      </div>

      <Footer />

      <style jsx>
        {`

          .full-width-slider.homepage
          {
            margin: 185px auto -166px ;
          }        
          .full-width-slider.homepage .singleSlider .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item img
          {
            border-radius: 0px !important;
          }        

          .wrapper {
            max-width: 1280px;
            margin: 185px auto 0 ;
          }

          .suggestion_product_container{
            background: #F8F8F8 ;
            border-radius: 19px ;
          }

          @media screen and (max-width: 900px){
            .wrapper {
            max-width: 1280px;
            margin: 225px auto 0;
          }
          }

          @media screen and (max-width: 600px){
            .wrapper {
            max-width: 1280px;
            margin: 160px auto 0;
          }
          }
        `}
      </style>
    </div>
  );
};
export default Home;
