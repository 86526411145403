import React, { useState, useEffect } from "react";
import "./sideCartView.css"
import proImage from "../../../assests/product_img.png"
import DeleteBox from "../../../assests/delete_box.png"
import ArrowRight from "../../../assests/arrow_right.png"
import {
    deleteItemFromCart,
    deleteItemFromTempCart,
    emptyCart,
    emptyTempCart,
    addToCart,
    addToTempCart,
    cartItemsCount,
    tempCartItemsCount,
    cartList,
    tempCartList,
    pincodeDeliveryEstimate,
    applyCoupon,
    removeCoupon
} from "../../../utils/apiUrlsSetup"
import { toast } from "react-toastify"
import { useNavigate, Link } from "react-router-dom"
import HomeIcon from "../../../assests/home_icon.png"
import SelectPaymentMode from "../../shared-components/selectPaymentMode/selectPaymentMode"


const SideCartView = ({ setShowLoginModal, showCart,setOrderId, setShowCart, cartListData, getCartListData, setCartListData, getCarouselData, loadCarousalData, setBadgeCount, setBadgePrice, handleCartAddressChange, setReorderOpenCart,getRecentOrders,getProductDetails, onCartEmpty=()=>{} }) => {

    const authToken = localStorage.getItem("authToken") || "" ;
    const address = localStorage.getItem("address") || "" ;
    const pincode = localStorage.getItem("pincode") || "";
    const navigate = useNavigate()
    const [selectPaymentMode, setSelectPaymentMode] = useState(false)
    const [itemsCount, setItemsCount] = useState(0)
    const [deliveryTime,setDeliveryTime] = useState(null);
    const [couponCode, setCouponInputValue] = useState("")
    const [showEnterCoupon, setShowEnterCoupon] = useState(true)
    const [couponErrorCode, setCouponErrorCode] = useState("")
    const [cartSession, setCartSession] = useState( localStorage.getItem("cart-session") || "" )

    let item_out_of_stock = 1     
    useEffect(()=>{
        if(authToken){
            getCartItemsCount()
        }
        else if(cartSession){
            getCartItemsCount()
        }

    },[cartListData.items])

    const cartEmpty = async () => {
        if(authToken)
        {
            const response = await emptyCart({})
            if (response.ErrorCode === 0) {
                getCartListData()
    
                if(getCarouselData){
                    getCarouselData()
                }
            }
        }    
        else if(cartSession)
        {
            const response = await emptyTempCart({})
            if (response.ErrorCode === 0) {
                getCartListData()
    
                if(getCarouselData){
                    getCarouselData()
                }
            }
        }
        getRecentOrders && getRecentOrders()
        getProductDetails && getProductDetails()
        onCartEmpty()
    }

    const getCartItemsCount = async() =>{
        if(authToken)
        {    
            const res = await cartItemsCount({})
            if(res.ErrorCode === 0){
                
                const count = res?.Response?.items?.reduce((p,c)=>c?.quantity + p,0)
                setItemsCount(count)
                setBadgeCount(res?.Data?.total_count)
                setBadgePrice(res?.Data?.total_price)

            }


            const response = await cartList({})
            if(response.ErrorCode === 0){
                const count = response?.Response?.items?.reduce((p,c)=>c?.quantity + p,0)
                setItemsCount(count)
                
                setCouponInputValue(response?.Response?.discount?.coupon_code)
                if(response?.Response?.discount?.coupon_code)
                {
                    setShowEnterCoupon(false)
                }
            }
    
        }
        else if(cartSession)
        {
            const res = await tempCartItemsCount({})
            if(res.ErrorCode === 0){
                
                const count = res?.Response?.items?.reduce((p,c)=>c?.quantity + p,0)
                setItemsCount(count)
                setBadgeCount(res?.Data?.total_count)
                setBadgePrice(res?.Data?.total_price)

            }
            const response = await tempCartList({})
            if(response.ErrorCode === 0){
                const count = response?.Response?.items?.reduce((p,c)=>c?.quantity + p,0)
                setItemsCount(count)
                
                setCouponInputValue(response?.Response?.discount?.coupon_code)
                if(response?.Response?.discount?.coupon_code)
                {
                    setShowEnterCoupon(false)
                }
            }

        }    

        // if(response.ErrorCode === 0){
            
        //     setCouponInputValue(res?.Response?.discount?.coupon_code)
        //     if(res?.Response?.discount?.coupon_code)
        //     {
        //         alert(couponCode)
        //         setShowEnterCoupon(false)
        //     }
        // }

    }

    const updateCartData = async (cartData) => {
        const formData = new FormData()
        formData.append("offer_price", cartData.offer_price)
        formData.append("rate", cartData.rate)
        formData.append("quantity", cartData.quantity)
        formData.append("product_id", cartData.product_id)

        const response = await addToCart(formData)
        if (response.ErrorCode === 0) {
            getCartListData()
            if(authToken){
                getRecentOrders()
            }
            loadCarousalData()
        }

    }

    const deleteCartItems = async (cartId) => {
        const formData = new FormData()
        formData.append("cart_id", cartId)
        if(authToken)
        {
            const data = await deleteItemFromCart(formData)
            if (data.ErrorCode === 0) {
                toast.success("Item removed")
                getCartListData()
    
                getCarouselData()
            }
    
        }
        else
        {
            const data = await deleteItemFromTempCart(formData)
            if (data.ErrorCode === 0) {
                toast.success("Item removed")
                getCartListData()
    
                getCarouselData()
            }
    
        }
        
    }

    const decrement = (item, index) => {
        if (item.quantity > 0) {
            const copyCartListData = { ...cartListData }

            copyCartListData.items[index].quantity = Number(item.quantity) - 1;
            setCartListData(copyCartListData)
            updateCartData(copyCartListData.items[index])
        }
    }

    const increment = (item, index) => {

        if (item.quantity < item.avaliable_stock) {
            const copyCartListData = { ...cartListData }

            copyCartListData.items[index].quantity = Number(item.quantity) + 1;
            setCartListData(copyCartListData)
            updateCartData(copyCartListData.items[index])

        }
    }

    const gotoSelectPaymentMode = (totalBill) => {
        if(authToken && cartListData.items && cartListData.items.length) 
        {
            setShowCart(false)
            setSelectPaymentMode(true)                      
        }
        else{
            setShowCart(false)
            toast.info('Please Login/SignUp First.')
            setShowLoginModal(true)
        }

    }

    const showCartHandler = () =>{
        setShowCart(false)
        if(setReorderOpenCart){
            setReorderOpenCart(false)
        }
    }

    const getSavingAmount = () =>{
        if(cartListData.items){
            const totalAmount = cartListData.items.reduce((p, c)=> {
                return c?.offer_price ? p + (c?.rate - c?.offer_price) : p;
            },0)
            return totalAmount;
        }
    }
    const handleCartCoupon = () => {
        // let formData = new FormData()
        // formData.append()
        if(!authToken)
        {
            setShowCart(false)
            toast.info("Please Login/SignUp to apply the Coupon")
            setShowLoginModal(true)
        }
        else{
            setCouponErrorCode("")
    
            const response =  applyCoupon({"coupon_code": couponCode}).then((x)=>{
                if (x.ErrorCode === 0) {
                    toast.success("Coupon Applied")
                    getCartListData()
                    setShowEnterCoupon(false);
                }
                else
                {
                    if(x.ErrorCode === -100)
                    setCouponErrorCode(x?.CouponResponse?.msg)
                    // console.log(x);
                }
                
            })

        }
        
    }
    
    const removeCartCoupon = () => {
        // call discard coupon
        setCouponInputValue("")
        setCouponErrorCode("")
        const response =  removeCoupon({}).then((x)=>{
            if (x.ErrorCode === 0) {
                toast.success("Coupon Removed")
                getCartListData()
                setShowEnterCoupon(true);
            }
            else
            {
                alert("There is Some Error in Coupon Implementation.")
            }
            // setShowEnterCoupon(true);                  
        })

    }

    useEffect(()=>{
        if(pincode){
            pincodeDeliveryEstimate({pincode}).then(({ItemResponse})=>{
            setDeliveryTime(ItemResponse);
            })
        }
    },[pincode])
    
    return (
        <>
            {showCart ?
                <div
                    className={`offcanvas offcanvas-end right_cart_list ${showCart ? "show visible" : null}`}
                    tabindex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                >
                    <div className="offcanvas-header mb-1">
                        <h2 className="offcanvasRightLabel fw-bold font_heading">My Cart</h2>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={showCartHandler}
                        >
                        </button>
                    </div>
                    <div className="offcanvas-body p-0">
                        <div className="top_div border-bottom px-3 pb-1">
                            <p className="m-0 font_13 text-secondary d-flex font_family_common">{itemsCount ? itemsCount : "0"} Items</p>
                            <span className="d-flex justify-content-between align-items-center">
                                <h6 className="m-0 fw-bold lh-sm font_sub_heading"> Delivery {deliveryTime?.delivery_expected_time}</h6>
                                <span
                                    className=" d-flex cursor-pointer"
                                    onClick={cartEmpty}
                                >
                                    <p className="m-0 text-secondary font_13 font_family_common">Empty Cart</p>
                                    <img src={DeleteBox} className="ms-1" height="16px" width="18px" alt="" />
                                </span>
                            </span>
                        </div>

                        <div className="cart_container">

                            {cartListData.items ? (cartListData.items || []).map((item, index) => {
                                return <div className="cart_list row m-0 border-bottom ps-3 py-3" key={index}>
                                    <div className="col-3 cart_list_item p-0 p-1 border rounded">
                                        <div className="cart_image">
                                            <img src={item.image && item.image !== "0" ? item.image : proImage} className="w-100 d-block" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-9 pe-0 cart_product_details">
                                        <p className="m-0 product_name font_paragraph">{item.product_name}</p>
                                        {/* <p className="m-0 text-secondary font_paragraph">Quantity - {item.quantity}</p> */}
                                        <div className="card_bottom d-flex align-items-center mt-3">
                                            <span className="product_price fw-bold font_family_common">₹{Math.floor(item.offer_price)}</span>
                                            {parseFloat(item.rate) > parseFloat(item.offer_price) ? 
                                                <span className="ms-2 text-secondary text-decoration-line-through fw-lighter">₹{Math.floor(item.rate)}</span>                                            :''
                                            }                                        
                                            <span className="delete_item_btn ms-auto d-flex align-items-center">
                                            <span style={{display:'none'}}>

                                            {item && item.is_stock ? 
                                                item_out_of_stock==0 ? item_out_of_stock=0 : item_out_of_stock=item.is_stock 
                                                : item_out_of_stock=item.is_stock 
                                            }
                                            </span>

                                                {item && item.is_stock?
                                                    item.quantity === 0?
                                                        <button
                                                            type="button"
                                                            className="rounded px-4 py-2 text_green bg-white border border-success ms-auto"
                                                            onClick={() => { increment(item, index) }}
                                                        >
                                                            ADD
                                                        </button>
                                                        :
                                                        item.offer_price?
                                                            <button className="bg_green px-3 py-2 d-flex justify-content-between align-items-center text-white font-semibold rounded-lg">
                                                            <span
                                                                className="pe-1 me-1"
                                                                onClick={() => { decrement(item, index) }}
                                                            >
                                                                &minus;
                                                            </span>
                                                            {item.quantity}
                                                            <span
                                                                className="ps-1 ms-1"
                                                                onClick={() => { increment(item, index) }}
                                                            >
                                                                &#10011;
                                                            </span>
                                                        </button>
                                                        :
                                                        null
                                                        
                                            
                                                    : 
                                                    'Out of Stock'
                                                }
                                                
                                                <img
                                                    src={DeleteBox}
                                                    className="ms-3 cursor-pointer"
                                                    alt=""
                                                    onClick={() => { deleteCartItems(item.cart_id) }}
                                                />
                                            </span>
                                        </div>
                                        {item?.["group_price"] ?
                                        <div dangerouslySetInnerHTML={{ __html: item?.["group_price"] }} className="small text-green-700 mt-3" />
                                        :null
                                        }
                                    </div>
                                </div>

                            })

                                :

                                <div className="empty_cart_view d-flex justify-content-center align-items-center">
                                    <p className="m-0 text-secondary text-center font_heading">
                                        Cart Is Empty
                                        <p className="m-0 text-dark fw-bold font_sub_heading">Please select items to place your order.</p>
                                    </p>

                                </div>}
                        {cartListData.items ?
                        <>

                        <div className="cart_order_details row m-0 py-3">
                            <label>Promo code / coupon</label>
                            {showEnterCoupon?
                                <div className="coupon_section d-flex mb-2">
                                    <div className="col-9">
                                        <input className="form-control" placeholder="Enter Coupon Code" value={couponCode} onChange={(e) =>
                                            setCouponInputValue(e?.target?.value)
                                        }/>
                                        <p className="text-red">{couponErrorCode}</p>
                                    </div>
                                    <div className="col-3 ml-1">
                                        <button onClick={handleCartCoupon} className="form-control btn btn-md text-white fw-bold align-items-center cursor-pointer font_family_common bg_green">Apply</button>
                                    </div>
                                </div>                            
                                :null
                            }
                            {!showEnterCoupon?
                                <div className="coupon_section d-flex mb-2">
                                    <div className="col-12">
                                        <div className="coupon_applied_box">
                                            <i className="fa fa-solid fa-tags mx-2"></i>
                                            <span className="couponCode">{couponCode}</span>
                                            <a className="remove_coupon_btn float-right" onClick={removeCartCoupon}>Remove</a>
                                        </div>
                                        <p className="text-green">Promo code added</p>
                                    </div>
                                </div>
                            :null
                            }


                            <div className="col-12 d-flex justify-content-between">
                                <p className="m-0 font_paragraph">{`Sub Total (incl. taxes)`} </p>
                                <p className="m-0 font_paragraph">₹{Math.floor(cartListData.sub_total)} </p>
                            </div>
                            {cartListData?.discount ?                                                    
                            <div className="col-12 d-flex justify-content-between">
                                <p className="m-0 font_paragraph">Coupon ({cartListData?.discount?.coupon_code})</p>
                                <p className="m-0 font_paragraph">- ₹{Math.floor(cartListData?.discount?.discount)}</p>
                            </div>
                            :null
                            }
                            {/* {cartListData.total_tax ? 
                            <div className="col-12 d-flex justify-content-between">
                                <p className="m-0 font_paragraph">{`${cartListData.total_tax[0].title} (${cartListData.total_tax[0].rate} %)`} </p>
                                <p className="m-0 font_paragraph">+ ₹{Math.floor(cartListData.total_tax[0].tax_amount)} </p>
                            </div> : null} */}
                            <div className="col-12 d-flex justify-content-between">
                                <p className="m-0 font_paragraph">Delivery Charges</p>
                                <p className="m-0 font_paragraph">+ ₹{cartListData.delivery_fee ? Math.floor(cartListData.delivery_fee) : 0}</p>
                            </div>
                                                                                

                            <div className="col-12 d-flex justify-content-between">
                                <p className="m-0 font_basic">Bill Total</p>
                                <p className="m-0 font_basic">₹{cartListData?.discount ?  cartListData.total && Math.floor(cartListData.total)-Math.floor(cartListData?.discount?.discount): cartListData.total && Math.floor(cartListData.total) }</p>
                            </div>

                            <div className="col-12 d-flex justify-content-center">
                                <p className="m-0 font_paragraph">Your total saving is ₹{ cartListData?.discount?.discount ? getSavingAmount()+cartListData?.discount?.discount : getSavingAmount()}</p>
                            </div>

                        </div>
                        {cartListData.total < 999
                        ?
                        <div className="row m-0 py-2">
                            <p className="m-0 font_basic">Minimum Cart Value</p>
                            <p className="m-0 font_paragraph lh-sm">Minimum order value is ₹999</p>
                        </div>
                        :
                        null                        
                        }
                        <div className="row m-0 py-2">
                            <p className="m-0 font_basic">Cancellation Policy</p>
                            <p className="m-0 font_paragraph lh-sm">Order can not be cancelled once packed for delivery. In case of unexpected delays, a refund will be provided, if applicable.</p>
                        </div>
                        </>
                        
                        : null}
                        </div>


                        <div className=" total_amount position-absolute bg-white pt-2">
                            <div className="row m-0 mb-2 px-2">
                                <div className="col-2 d-flex justify-content-center align-items-center"><img src={HomeIcon} alt="" /></div>
                                <div className="col-8 d-flex justify-content-center align-items-center p-0">{address}</div>
                                <div 
                                    className="col-2 d-flex justify-content-center align-items-center text_green font_basic cursor-pointer"
                                    onClick={handleCartAddressChange}
                                >
                                    Change
                                </div>
                            </div>
                            {/* <p className="m-0 mb-1 font_13 text-dark fw-bold ms-2 font_family_common">Delivery Charges : </p> */}
                            <span className={`d-flex justify-content-between align-items-center rounded px-3 py-2 mx-2 bg_green ${item_out_of_stock ?"":"disabled"} ${cartListData.total >= 999 ?"":"disabled"}` }>
                                <span className="d-flex align-items-center">
                                    <p className="m-0 text-white fw-bold fs-6 font_family_common"> {itemsCount ? itemsCount : 0 } Items -</p>
                                    <p className="m-0 text-white fw-bold fs-6 ms-3 font_family_common" > ₹{cartListData?.discount ?  cartListData.total && Math.floor(cartListData.total)-Math.floor(cartListData?.discount?.discount): cartListData.total && cartListData.total ? Math.floor(cartListData.total) : 0 } <span className="font_paragraph">Incl. all taxes</span> <span className="text-decoration-line-through fw-lighter"></span> </p>
                                </span>
                                {cartListData.total < 999
                                ?
                                <p
                                    className="m-0 text-white fw-bold fs-6 d-flex align-items-center cursor-pointer font_family_common disa"                                    
                                >
                                    Proceed
                                    <img src={ArrowRight}
                                        className="proceed_btn_arrow"
                                        alt=""
                                    />
                                </p>
                                :
                                <p
                                    className="m-0 text-white fw-bold fs-6 d-flex align-items-center cursor-pointer font_family_common"
                                    onClick={gotoSelectPaymentMode}
                                >
                                    Proceed
                                    <img src={ArrowRight}
                                        className="proceed_btn_arrow"
                                        alt=""
                                    />
                                </p>
                                }


                            </span>

                        </div>

                    </div>
                </div>
                : null}

                {selectPaymentMode ? 
                <SelectPaymentMode 
                    setShowCart={setShowCart}
                    selectPaymentMode={selectPaymentMode} 
                    setSelectPaymentMode={setSelectPaymentMode}
                    cartListData={cartListData}
                    setOrderId={setOrderId}
                    getCartListData={getCartListData}
                />
                 : null}
                
        </>
    )
}

export default SideCartView;