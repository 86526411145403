import React,{useState, useEffect} from "react";
import "./footerAllCategoryDetails.css";
import { categoryData } from "../../utils/apiUrlsSetup";
import { Header,Footer } from "../shared-components";
import Loader from "../shared-components/loader/loader";

const FooterAllCategoryDetails = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {} ;

    const [categoryListData, setCategoryListData] = useState([]) ;
    const [showLoader, setShowLoader] = useState(false)
    const pincode = localStorage.getItem("pincode") || '' ;
    useEffect(()=>{
        getCategoryData()
    },[])

    const getCategoryData = async()=>{
        setShowLoader(true)

        const formData = new FormData();
        formData.append("list" , "all")
        formData.append("pincode" , pincode)

        let response = await categoryData(formData)
        if(response && response.ErrorCode === 0 && response.Response!=null){
            setShowLoader(false)
            setCategoryListData(response.Response.category)
        }
    }

    return(
        <>
            <Header 
                userDetails={userDetails}
            />
            <div className="row m-0 px-3 px-lg-0">
            {showLoader ? <Loader bottom="50%" right="48%" /> : ''}
                <div className="col-0 col-lg-2"></div>
                    <div className="col-12 col-lg-8 footer_category_details_page px-3 rounded pb-4">
                        <p className="m-0 mt-3 font_heading px-3">Categories</p>
                        {categoryListData.length && categoryListData.map((category, index)=>{
                            return <div className="row m-0 mt-2" key={index}>
                            <p className="m-0 font_sub_heading">{category.category_name}</p>
                            <div className="col-12 col-lg-6">
                                <div className="row m-0">
                                    {category.subcategory ? category.subcategory.map((value, index)=>{
                                        return <div className="col-6 p-0" key={index}>
                                        <a 
                                            className="m-0 mt-1 cursor-pointer font_family_common d-inline-block"
                                            href={`/product-category/${category.id}/${value.id}`}
                                        >
                                        {value.category_name}
                                        </a>
                                    </div>
                                    })  
                                    :
                                    <>
                                    <div className="col-6 p-0">
                                        <p className="m-0 mt-1 cursor-pointer font_family_common d-inline-block">Menu 1</p>
                                    </div>
                                    <div className="col-6 p-0">
                                        <p className="m-0 mt-1 cursor-pointer font_family_common d-inline-block">Menu 2</p>
                                    </div>
                                    </>

                                    }
                                </div>
                            </div>                  
                        </div>
                        })
                        }
                    </div>
                <div className="col-0 col-lg-2"></div>
            </div>
            <Footer/>
        </>
    )
}

export default FooterAllCategoryDetails ;