import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {
    Header,
    Footer,
} from "./../shared-components";
import {
    contactUsRequest,categoryData
} from "./../../utils/apiUrlsSetup";
import Loader from "../shared-components/loader/loader"
import emailIcon from "./../../assests/email.png"
import addressIcon from "./../../assests/address.png"
    
const ContactUs = (props) => {
    const pincode = localStorage.getItem("pincode") || "";
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {} ;
    const [badgeCount, setBadgeCount] = useState(0)
    const [showLoader, setShowLoader] = useState(false)

    const authToken = localStorage.getItem("authToken") || '' ;
    const [successMessage, setSuccessMessage] = useState(false);
    const [detail, setDetail] = React.useState({
        name: props?.location?.state?.profileDetails?.name || "",
        email: props?.location?.state?.profileDetails?.email || "",
        phone: localStorage.getItem("phone") || "",
        query: props?.location?.state?.profileDetails?.query || "",
    });
    useEffect(()=>{
    },[])

      
    const handleChange = (e) => {
        setDetail({ ...detail, [e?.target?.name]: e?.target?.value });
    };

    const handleSubmit = () => {
        setShowLoader(true);
        let requestForOpenContactUs = {
            name: detail.name,
            mobile_no: detail.phone,
            email: detail.email,
            subject: detail.query,
            query: detail.query,
        };
        let requestForContactUs = {
            name: detail.name,
            mobile_no: localStorage.getItem("phone") || detail.phone,
            email: detail.email,
            subject: detail.query,
            query: detail.query,
        };
        // let url = localStorage.getItem("token") ? "/contact-us" : "/contact-us-all";
        let contactusRequest = localStorage.getItem("token") ? requestForContactUs : requestForOpenContactUs
        const response = contactUsRequest(contactusRequest) 
     
        if (response?.data?.ErrorCode === 0) {
            setShowLoader(false)
            setSuccessMessage(true);
        }
    }

    return( <>
        {showLoader ? <Loader bottom="50%" right="47%" /> : null}
        <Header userDetails={userDetails} badgeCount={badgeCount} />
        <div className="container px-3 mt-12 mb-5 pb-3 mb-lg-0 pb-lg-0">
            <div className="row m-0 contact_us_page pt-20 ">
                <div className="col-12">
                    <div className="row m-0 d-flex ps-1 ms-1">
                        <h1 className="text-dark fw-bold text-center mb-3 page-title">
                            Contact Us
                        </h1>
                    </div>


                    <div className="bg-white lg:p-4">
                        <h3 className="text-xl font-bold py-4 flex-1">
                            Head Office: Glen Appliances Pvt. Ltd.
                        </h3>
                        <p className="flex"><img  className="mr-2"   src={emailIcon} width="20px"/> 
                            <a
                                href="mailto:enquiry@glenindia.com"
                                className="text-sm  font-bold"
                            >
                                enquiry@glenindia.com
                            </a>
                        </p>
                        <p className="text-sm font-bold flex items-start mt-2">
                            <img className="mr-1" width="25px" height="25px" src={addressIcon}/> 
                            I - 34, DLF Industrial Area, Phase I, Faridabad,<br/> Haryana 121003
                            <br/>
                            <a href="tel:+919266655555" className="contents">
                            Helpline : 9266655555
                            </a>
                        </p>

                        <h3 className="text-xl font-bold py-4 flex-1">
                            For any query please fill the below form.
                        </h3>

                        {!successMessage ? (
                            <div className="form">
                                <div className="order-id flex flex-col">
                                    <label>Name*</label>
                                    <input
                                    placeholder="Enter your name"
                                    value={detail.name}
                                    onChange={handleChange}
                                    name="name"
                                    className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                    />
                                </div>
                                <div className="order-id flex flex-col">
                                    <label>Email*</label>
                                    <input
                                    placeholder="Enter your email"
                                    value={detail.email}
                                    onChange={handleChange}
                                    name="email"
                                    className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                    />
                                </div>
                                <div className="order-id flex flex-col">
                                    <label>Phone*</label>
                                    <input
                                    placeholder="Enter your phone"
                                    name="phone"
                                    value={localStorage.getItem("phone") || ""}
                                    onChange={handleChange}
                                    className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                    />
                                </div>

                                <div className="order-id flex flex-col">
                                    <label>Write your Query*</label>
                                    <textarea
                                    name="query"
                                    onChange={handleChange}
                                    className="my-3 w-full  p-4 bg-white rounded-md  shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                    ></textarea>
                                </div>
                                <button
                                    disabled={Object.values(detail).includes("")}
                                    className={` ${
                                    true ? "disabled:opacity-50" : ""
                                    } w-full my-5 rounded-full bg-primary text-md h-12 text-white cursor-pointer`}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        ) : (
                            <>
                            <div className="text-center">
                                <p className="text-primary">Thanks For Contacting us</p>
                                <p>Someone Assist you shortly</p>
                            </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        <style jsx>
            {`
            
            .contact_us_page{
                margin-top: 125px !important;
                box
            }

            `}
        </style>
    </>
    );
};

export default ContactUs;