import { Footer,
        Header,
        ProductSlider,
      } from "./shared-components";
import ProductPageLeftDetailsMobile from "./shared-components/productPageLeftDetails/productPageLeftDetailsMobile"
import ProductPageLeftDetailsWeb from "./shared-components/productPageLeftDetails/productPageLeftDetailsWeb"
import ProductPageRightDetails from "./shared-components/productPageRightDetails/productPageRightDetails"
import ProductDetailTabs from "./shared-components/productDetailTabs/productDetailTabs"
import "./productPage.css"
import React, { useState, useEffect,useRef } from "react";
import { useParams } from "react-router-dom";
import { addToCart,addToTempCart, categoryProductList } from "../utils/apiUrlsSetup"
import Loader from "../components/shared-components/loader/loader"
import Carousel from 'react-multi-carousel';
import Popup from 'reactjs-popup';
import Default from "../assests/slide_img.jpg"
import 'reactjs-popup/dist/index.css';
import { productDetails, cartItemsCount,tempCartItemsCount,categoryData} from "../utils/apiUrlsSetup"
import { toast } from "react-toastify";
import ImagesMiniCarousel from "./shared-components/imagesMiniCarousel/imagesMiniCarousel";

import ImageMagnifier from "./shared-components/imageMagnifier/imageMagnifier"
import { useNavigate } from "react-router-dom";
import CategoryHeaderList from "./shared-components/categoryHeaderList/categoryHeaderList";

const ProductPage = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [activeImage, setActiveImage] = useState('')
  const [badgeCount, setBadgeCount] = useState(0)
  const [badgePrice, setBadgePrice] = useState(0)
  const [similarItems, setSimilarItems] = useState([])

  const { id } = useParams()
  const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};
  const authToken = localStorage.getItem("authToken") || '';
  const pincode = localStorage.getItem("pincode") || '';
  const [cartSession, setCartSession] = useState( localStorage.getItem("cart-session") || "" )

  const windowSize = useRef([window.innerWidth, window.innerHeight])
  
  useEffect(() => {
    getCategoryData()
    getCategoryDataList()
    if (id) {
      getProductDetails()
    }
    window.scrollTo(0, 0)

  }, [id])

  const getCartItemsCount = async () => {
    if(authToken)
    {
      const res = await cartItemsCount({})
      if (res.ErrorCode === 0) {
        setBadgeCount(res?.Data?.total_count)
        setBadgePrice(res?.Data?.total_price)
      }
    }
    else if(cartSession)
    {
      const res = await tempCartItemsCount({})
      if (res.ErrorCode === 0) {
        setBadgeCount(res?.Data?.total_count)
        setBadgePrice(res?.Data?.total_price)
      }

    }
  }

  const getProductDetails = async () => {
    setShowLoader(true)
    const formData = new FormData();
    formData.append("product_id", id)
    formData.append("pincode", pincode)
    let response = await productDetails(formData)
    if (response.ErrorCode === 0) {
      setShowLoader(false)
      const copyData = {...response.ItemResponse}
      copyData.item_discount = copyData.item_discount.split(".",1)
      setProductData(copyData)
      getSimilarProducts(copyData)
    }
  }

  const getSimilarProducts = async (copyData) => {
    setShowLoader(true)

    const obj = {
      category_id : copyData.category.category_id,
      pincode : pincode
    }

    let response = await categoryProductList(obj)
    setShowLoader(false)
    if (response && response.ErrorCode === 0) {
        setSimilarItems(
          [
            {
              type : "product_carousel",
              carousel_name: "You may also like",
              items : response.ItemResponse.category_products.filter((p)=> p?.product_id!= id)
            }
          ]
        )
    }
  }

  const addProductToCart = async (productData) => {

    const formData = new FormData()
    formData.append("offer_price", productData.discount_price)
    formData.append("rate", Number(productData.mrp))
    formData.append("quantity", productData.quantity)
    formData.append("product_id", productData.product_id)

    if(authToken)
    {
      const response = await addToCart(formData)
      if (response.ErrorCode === 0) {
        // toast.success("Cart updated successfully.", { autoClose: 1000 })
        getCartItemsCount()
      }
      else if (response && response.message === "Unauthenticated.") {
        toast.error("Please login first.", { autoClose: 1200 })
      }
    }
    else if(cartSession)
    {
      const response = await addToTempCart(formData)
      if (response.ErrorCode === 0) {
        // toast.success("Cart updated successfully.", { autoClose: 1000 })
        getCartItemsCount()
      }
      else if (response && response.message === "Unauthenticated.") {
        toast.error("Please login first.", { autoClose: 1200 })
      }
    }

  }

  const decrement = () => {
    if (productData.quantity > 0) {
      const copyProductData = { ...productData }


      copyProductData.quantity = copyProductData.quantity - 1
      addProductToCart(copyProductData)
      setProductData(copyProductData)
    }
  }

  const increment = () => {
    if (productData.quantity < productData.current_stock && inputValue < productData.current_stock) {
      const copyProductData = { ...productData }

      if (inputValue) {
        copyProductData.quantity = inputValue
      }
      else {
        copyProductData.quantity = copyProductData.quantity + 1
      }
      addProductToCart(copyProductData)

      setProductData(copyProductData)

    }
    else {
      toast.error("This much quantity is not in stock.")
    }
  }

  const addBtnHandler = (data) => {
    if (authToken !== '') {
      increment(data)
    }
    else if (cartSession !== '') {
      increment(data)
    }
    else {
      toast.error("Please Login First", { autoClose: 1200 })
    }
  }
  const [subHeaderData, setSubHeaderData] = useState([]);
  const [moreData, setMoreData] = useState([]);
  const [categoryId, setCategoryId] = useState()
  const [categoryListData, setCategoryListData] = useState([])
  const getCategoryData = async()=>{
    setShowLoader(true)
    let response = await categoryData({"pincode":pincode})
    if(response && response.ErrorCode === 0){
      setCategoryListData(response.Response.category)
      setShowLoader(false)
    }
  }

  const getCategoryDataList = async () => {
    let response = await categoryData({"pincode":pincode})
    if ( response && response.ErrorCode === 0) {
        setCategoryListData(response.Response.category)
        const copyCategoryListData = [...response.Response.category]
        const moreData = copyCategoryListData.splice(8, 50)
        setSubHeaderData(copyCategoryListData)
        setMoreData(moreData)
    }
  }
    
  const responsive={
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  }

  return (
    <>
      {showLoader ? <Loader right="50%" bottom="47%" /> : null}
      <Header userDetails={userDetails} badgeCount={badgeCount} badgePrice={badgePrice} getProductDetails={getProductDetails} />
      {/* <div className="pb-5">
        <CategoryHeaderList
          categoryHeaderData={subHeaderData}
          moreData={moreData}
          setCategoryId={setCategoryId}
          categoryId={categoryId}
        />
      </div> */}

      <div className="wrapper product_page">
        <div className="productDetails">
          <div className="productContainer">
            
            <div className="product-image-overview d-none d-lg-block" id="style-5">
              <div className="image-highlighter product_main_image d-flex align-items-center justify-content-center">
                <ImageMagnifier
                  productData={productData}
                  activeImage={activeImage}
                />
              </div>
              <div className="bottom_multi_images row m-0 mb-5 mt-3 pe-5 position-relative">
                <ImagesMiniCarousel
                  productData={productData}
                  activeImage={activeImage}
                  setActiveImage={setActiveImage}
                />
              </div>
              <div class="Product__Divider-sc-18z701o-1 piGHb"></div>
              <ProductPageLeftDetailsWeb productData={productData} />
            </div>
            <div className="all-about-product">
              <div class="pdp-react-product-info">
                <div class="ProductInfoCard__ZoomedImageContainer-sc-113r60q-1 gbMUp">
                  <div
                    id="portal"
                    class="ProductInfoCard__ZoomedImage-sc-113r60q-2 IoTiZ"
                  ></div>
                </div>

                <div className={`responsive_product_page_carousel d-flex justify-content-center align-items-center my-2 mb-3 d-lg-none`}>
                  { productData?.multiple_images?.length > 0 
                  ?''
                  :
                  <img src={productData?.product_image}/>
                  }
                  <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                  > 
                  
                  { (productData.multiple_images || []).map((value, index)=>{
                      return <img
                                key={index+1}
                                src={value.image ? value.image : Default}
                                className="product_page_responsive_carousel_img"
                              />
                    }) 
                    }
                    { windowSize.current[0]<768?
                      <img
                      key={0}
                      src={productData.product_image}
                      className="product_page_responsive_carousel_img"/>

                    :null
                    }
                  </Carousel>
                </div>

                <div class="ProductInfoCard__Breadcrumb-sc-113r60q-4 cfIqZP">
                  <a
                    class="ProductInfoCard__BreadcrumbLink-sc-113r60q-5 hRvdxN font_family_common"
                    href="/"
                  >
                    Home
                  </a>{" "}
                  /{" "}
                  <span
                    class="ProductInfoCard__BreadcrumbLink-sc-113r60q-5 hRvdxN font_family_common"
                    href="/"
                    onClick={() => {
                      navigate({ pathname: `/product-category/${productData.category.parent_category_slug}/${productData.category.category_slug}` });
                    }}
                  >
                  {productData && productData.category && productData.category.parnet_category_name? productData.category.parnet_category_name : "Product Details"}
                  </span>{" "}
                  /{" "}
                  <span
                    class="ProductInfoCard__BreadcrumbLink-sc-113r60q-5 hRvdxN font_family_common"
                    href="/"
                    onClick={() => {
                      navigate({ pathname: `/product-category/${productData.category.parent_category_slug}/${productData.category.category_slug}` });
                    }}
                  >
                    {productData && productData.category ? productData.category.category_name : "Product Details"}
                  </span>{" "}
                  /{" "}
                  <span class="ProductInfoCard__BreadcrumbProductName-sc-113r60q-6 lhxiqc font_family_common">
                    {productData && productData.product_name}
                  </span>
                </div>
                <h1 class="ProductInfoCard__ProductName-sc-113r60q-7 kPXfrg font_heading">
                  {productData && productData.product_name}
                </h1>
                <p class="">
                  {productData && productData.short_description}
                </p>
                <div class="ProductInfoCard__BrandContainer-sc-113r60q-9 exyKqL">
                <b>Brand - </b>
                  <a
                    class="ProductInfoCard__PDPLink-sc-113r60q-0 fKFudv font_basic"
                    href={`/brand/${productData.brand}/${productData.brand_id}`}
                  >
                    {productData && productData.brand} 
                  </a>
                </div>
                {/* <div class="ProductVariants__VariantsContainer-sc-1unev4j-0 hBlaVh">
                  <div class="ProductVariants__VariantsCardSlider-sc-1unev4j-1 ftBfYR">
                    <div class="ProductVariants__VariantCardContainer-sc-1unev4j-2 kKyfef">
                      <div class="ProductVariants__VariantCard-sc-1unev4j-3 bEuNss">
                        <div class="ProductVariants__RadioButtonContainer-sc-1unev4j-4 jqJIBg">
                          <div class="ProductVariants__RadioButtonOuter-sc-1unev4j-5 jcgpFW">
                            <div class="ProductVariants__RadioButtonInner-sc-1unev4j-6 fgFqYM"></div>
                          </div>
                        </div>
                        <div class="ProductVariants__VariantDetailsContainer-sc-1unev4j-7 fvkqJd">
                          <p class="ProductVariants__VariantUnitText-sc-1unev4j-8 bZvIga font_family_common">
                             {productData.item_weight ? productData.item_weight : 0} gm 
                          </p>
                          
                          {parseFloat(productData.mrp) > parseFloat(productData.discount_price) ?
                          <div class="ProductVariants__PriceContainer-sc-1unev4j-9 jjiIua font_basic">
                            ₹{productData ? Math.floor(productData.discount_price) : "00"}{" "}
                            <span class="ProductVariants__MRPText-sc-1unev4j-10 jEinXG font_paragraph ">
                              ₹{productData ? Math.floor(productData.mrp) : "00"}
                            </span>
                          </div>
                          :  
                          <div class="ProductVariants__PriceContainer-sc-1unev4j-9 jjiIua font_basic">
                            ₹{productData ? Math.floor(productData.mrp) : "00"}{" "}
                          </div>
                          }
                        </div>
                      </div>
                      <div class="ProductVariants__OfferContainer-sc-1unev4j-12 cYfAAX font_basic">
                      {parseFloat(productData.mrp)>parseFloat(productData.discount_price) ?
                        `${productData.item_discount}% Off`
                        :''
                      }
                      </div>
                    </div>
                  </div>
                </div> */}


                {parseFloat(productData?.mrp) > parseFloat(productData?.discount_price) ?
                  <div class="product_price font_basic">
                    ₹{productData ? Math.floor(productData?.discount_price) : "00"}{" "}
                    <span class="ProductVariants__MRPText-sc-1unev4j-10 jEinXG mrp_price">
                    ₹{productData ? Math.floor(productData?.mrp) : "00"}
                    </span>
                    <span className="discount_percentage">
                    {parseFloat(productData?.mrp)>parseFloat(productData?.discount_price) ?
                      `${productData?.item_discount}% Off`
                      :''
                    }
                    </span>
                  </div>
                  :  
                  <div class="product_price font_basic">
                    {productData?.mrp ?
                    <span>
                      ₹{productData ? Math.floor(productData?.mrp) : "-"}
                    </span>
                    :
                    " "
                    }
                  </div>
                }
                <p className="font_basic">Inclusive of all taxes</p>


              </div>


              {productData?.["group_price"] ?
              <div dangerouslySetInnerHTML={{ __html: productData?.["group_price"] }} className="group_price mt-3" />
              :null
              }
              
              <div class="font_paragraph variants_options">
                <p className="py-2">
                  <b>Sizes - </b>
                </p>                
                {productData?.variants ? productData?.variants.map((details, index) => (
                  <span onClick={()=>{                  
                    navigate({ pathname: `/product/${details.product_slug}/${details.product_id}` });
                  }}
                  className={productData?.variant_combo==details.variant_combination?"active":null}                                
                  > {details.variant_combination}</span>
                )): null}
                <div className="clearfix"></div> 
              </div>            





              {productData.quantity > 0 ?
                <button
                  className="bg_green mt-3 mb-lg-3 h-12 fs-5 font-semibold py-1 my-2 px-4 text-white rounded-lg d-flex align-items-center card_add_btn"
                >
                  <span
                    className="pe-1"
                    onClick={() => { decrement(productData) }}
                  >
                    &minus;
                  </span>

                  <Popup
                    className="search-popup"
                    trigger={
                      <button className="text-primary  cursor-pointer px-3 text-white fs-5 font_basic ">
                        {productData ? productData.quantity : 0}
                      </button>
                    }
                    modal
                  >
                    {(close) => (
                      <div className="modal d-flex position-relative w-100">
                        <div className="border-b mx-2 py-1 position-absolute font-bold text-base border-black flex justify-between items-center w-100 px-3">
                          Enter the Quantity here{" "}
                          <button
                            className="close cursor-pointer  text-2xl"
                            onClick={close}
                          >
                            &times;
                          </button>
                        </div>
                        <div className="content mx-2 my-2">
                          <div className="mt-4">
                            <input
                              min="0"
                              max={productData.current_stock}
                              type="number"
                              className="phone popup_quantity_input border bg-white rounded-full h-10 mb-2 mt-4 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4 font_paragraph"
                              placeholder="Quantity"
                              value={inputValue}
                              onChange={(e) =>
                                setInputValue(Number(e?.target?.value))
                              }
                            />

                          </div>

                          <button
                            className=" rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full font_basic"
                            onClick={() => {
                              if (inputValue) {
                                increment(productData);
                                setInputValue('')
                              }
                              close();

                            }

                            }
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    )}
                  </Popup>

                  <span
                    className="ps-1"
                    onClick={() => { increment(productData) }}
                  >
                    &#10011;
                  </span>
                </button>
                :

                <>
                  {productData.current_stock <= 0 ?
                    <p className="m-0 d-flex d-none align-items-center font-semibold py-2 font_13 text-danger text-uppercase font_basic"> Out Of Stock</p>
                    :
                    <button
                      className="px-4 mt-3 py-2 h-12 d-none font-semibold py-1 card_add_btn_preset  mb-lg-3 text_green rounded-lg font_basic"
                      onClick={() => { addBtnHandler(productData) }}
                    >
                      ADD
                    </button>}
                </>
              }
              <div class="Product__Divider-sc-18z701o-1 piGHb"></div>
              {/* <ProductPageLeftDetailsWeb productData={productData} /> */}

              {/* <ProductPageLeftDetailsMobile productData={productData} /> */}
              <ProductPageRightDetails productData={productData}/>
            </div>
          </div>
              <ProductDetailTabs productData={productData}/>
        </div>
        <div class="containers__Divider-sc-l8pmj8-1 bzZIsM"></div>
        <ProductSlider />
        <div className="row m-0 my-2">
          <ProductSlider 
          listData={similarItems} 
          setListData={setSimilarItems}  
          updateListData={getSimilarProducts} 
          getCartItemsCount={getCartItemsCount}
        />
        </div>
        <div class="containers__Divider-sc-l8pmj8-1 bzZIsM"></div>
      <div className="mx-3 mb-5 pb-3">
          <Footer />
        </div>
      </div>
      <style jsx>
        {`

        .product_page .product_price
        {
          color:#008000;
          font-size:30px !Important;
        }
        .mrp_price
        {
          font-size:20px;
        }
        .group_price
        { --tw-text-opacity: 1;
          color: rgba(0, 128, 0, var(--tw-text-opacity));
        }
        .discount_percentage{
          background:#008000;
          color:#ffffff;
          font-size:18px;
          padding:5px 10px;
          border-radius:8px;
          font-weight:400;
          margin-left:10px;
        }
          .wrapper {
            max-width: 1280px;
            margin: 165px auto 0;
          }
          .productDetails {
            min-height: calc(66vh - 86px);
            display: flex;
            flex-direction: column;
            max-width: 1280px;
            margin-left: auto;
            margin-right: auto;
          }
          .productContainer {
            flex: 1 1 0%;
            display: flex;
            -webkit-box-align: stretch;
            align-items: stretch;
          }
          .piGHb {
            background-color: rgb(242, 242, 242);
            height: 1px;
          }
          .product-image-overview {
            flex: 5 1 0%;
            height: 100%;
            // overflow-y: scroll;
            border-right: 1px solid rgb(242, 242, 242);
          }
          
          .hPfknV {
            color: rgb(28, 28, 28);
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            margin-top: 32px;
            margin-bottom: 0px;
          }

          @media (min-width: 1260px) {
            .product-detail-container {
              width: 570px;
              margin-bottom: 28px;
              border: 0px;
            }
          }
          .bqhpsC {
            margin-top: 16px;
          }
          .kORRGZ {
            color: rgb(31, 31, 31);
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            margin-top: 0px;
            margin-bottom: 8px;
            text-transform: capitalize;
          }
          .lnLDYa {
            color: rgb(102, 102, 102);
            font-size: 14px;
            line-height: 18px;
            overflow-wrap: break-word;
          }
          .bzZIsM {
            left: 0px;
            right: 0px;
            height: 1px;
            background-color: rgb(242, 242, 242);
          }
          
          @media (min-width: 1260px) {
            .ixAXec {
              padding-top: 48px;
              padding-left: 48px;
            }
          }
          .cfIqZP {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: rgb(54, 54, 54);
            margin: 0px;
          }
          .hRvdxN {
            text-decoration: none;
            cursor: pointer;
          }
          .lhxiqc {
            color: rgb(153, 153, 153);
            cursor: pointer;
          }
          .kPXfrg {
            color: rgb(0, 0, 0);
            font-size: 24px;
            line-height: 32px;
            font-weight: 400;
            margin: 8px 0px;
          }
          .fKFudv {
            text-decoration: none;
            color: rgb(12, 131, 31);
            font-size: 18px;
            line-height: 24px;
            margin-right: 4px;
            font-family: Okra-Medium;
          }
          .hBlaVh {
            margin: 20px 0px;
            display: flex;
            gap: 8px;
          }
          .ftBfYR {
            display: flex;
           
            width: 90%;
            max-width: 400px;
            gap: 8px;
          }
          .kKyfef {
            min-width: max-content;
          }
          .bEuNss {
            padding: 8px 12px;
            border: 1px solid rgb(177, 220, 156);
            border-radius: 8px 8px 0px 0px;
            cursor: pointer;
            background: rgb(255, 255, 255);
            display: flex;
          }
          .jqJIBg {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            padding: 0px 5px;
          }
          .jcgpFW {
            width: 15px;
            height: 15px;
            border: 1px solid rgb(12, 131, 31);
            border-radius: 50%;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
          }
          .fgFqYM {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgb(12, 131, 31);
          }
          .fvkqJd {
            padding-left: 7px;
          }
          .bZvIga {
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            margin-top: 0px;
            margin-bottom: 0px;
            color: rgb(31, 31, 31);
          }
          .jjiIua {
            margin-top: 2px;
            font-size: 14px;
            line-height: 18px;
            color: rgb(31, 31, 31);
          }
          .jEinXG {
            color: rgb(153, 153, 153);
            text-decoration: line-through;
          }
          .cYfAAX {
            padding: 4px;
            text-align: center;
            background: rgb(236, 255, 236);
            color: rgb(84, 178, 38);
            font-size: 10px;
            line-height: 13px;
            font-weight: 700;
            text-transform: lowercase;
            border-right: 1px solid rgb(177, 220, 156);
            border-bottom: 1px solid rgb(177, 220, 156);
            border-left: 1px solid rgb(177, 220, 156);
            border-image: initial;
            border-top: none;
            border-radius: 0px 0px 8px 8px;
          }
          .ibwbWV {
            margin-bottom: 32px;
          }
          .jgHBKQ {
            background-color: rgb(247, 255, 249);
            border: 0.5px solid rgb(12, 131, 31);
            border-radius: 8px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            text-align: center;
            -webkit-box-pack: center;
            justify-content: center;
            cursor: pointer;
            width: 137px;
            height: 48px;
          }
          .cdxXEt {
            position: relative;
            width: 100%;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            height: 100%;
          }
          .hMRJNG {
            font-family: Okra;
            font-style: normal;
            font-weight: 600;
            color: rgb(12, 131, 31);
            font-size: 19px;
            line-height: 25px;
          }
          @media (min-width: 1260px) {
            .bWmbbS {
              padding-left: 0px;
              margin-bottom: 0px;
              font-size: 16px;
            }
          }
          .first-row .column {
            margin: 16px 0;
            display: flex;
            align-items: center;
          }
          .column img {
            width: 56px;
            margin-right: 15px;
          }
          .first-row__name {
            margin-bottom: 4px;
            color: #000;
            font-size: 12px;
          }

          .first-row__description {
            font-size: 12px;
          }

          .vertical-align--middle {
            vertical-align: middle !important;
          }
          .color-black {
            color: #000;
          }

          .responsive_product_page_carousel .container{
            width:auto !important ;
          }

          .responsive_product_page_carousel .react-multi-carousel-list .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
              right:0 !important;
              background:white !important;
              color: black !important;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }

          .responsive_product_page_carousel .react-multi-carousel-list{
              padding: 0 10px;
          }

          .responsive_product_page_carousel .react-multi-carousel-list .react-multiple-carousel__arrow--left{
              background:white !important;
              left:6px !important;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              color: black !important;
          }

          .responsive_product_page_carousel .react-multi-carousel-list .react-multiple-carousel__arrow::before{
              color: black !important;
          }

          
        `}
      </style>
    </>
  );
};

export default ProductPage;
