import ReactImageMagnify from "react-image-magnify";

const ImageMagnifier = ({activeImage, productData}) => {
    return(
        <>
            <ReactImageMagnify {...{
                  smallImage: {
                    alt: 'product_image',
                    width: 550,
                    height: 550,
                    src: activeImage === '' ? productData["product_image"] : productData["multiple_images"][activeImage].image,
                    
                  },
                  largeImage: {
                    src: activeImage === '' ? productData["product_image"] : productData["multiple_images"][activeImage].image,
                    height:800,
                    width: 1250,
                  },
                  isHintEnabled: false,
                  shouldHideHintAfterFirstActivation: false,
                  enlargedImageContainerDimensions: {
                    width: 850,
                    height: 550,
                  },
                  className:"magnify_image_product",
                }} 
                />
        </>
    )
}

export default ImageMagnifier ;