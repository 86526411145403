
const ProductPageLeftDetailsWeb =({productData})=>{
    return(<>
        <div className="product-details pr-5">
                <div className="product-detail-container">
                  <div> 
                  
                  {productData && productData.packing_info ?
                  <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
                    <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
                      <span>Packaging Details</span>
                    </p>
                    <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13">
                      {productData.packing_info ? productData.packing_info :  '---'}
                    </div>
                  </div> : null }

                  {productData && productData.warranty_duration ?
                  <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
                    <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
                      <span>Warranty</span>
                    </p>
                    <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13">
                      {productData.warranty_duration ? productData.warranty_duration : '---'}
                    </div>
                  </div> : null }
                    {productData && productData.expiry_date ? <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
                      <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
                        <span>Expiry Date</span>
                      </p>
                      <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13">
                        {productData.expiry_date}
                      </div>
                    </div> : null}

                  </div>
                </div>
              </div>
    </>)
}

export default ProductPageLeftDetailsWeb ;