/* eslint-disable eqeqeq */
import React from "react"
import "./LeftSubCategories.css"
import productImage from "../../../assests/product_img.png";

const LeftSubCategories = ({subCategories, updateCategoryProductList, activeSubCategory}) => {
   
    return (
        <>
            <div className="sideBar p-0">
                <div className="sideBar-scroll" style={{"min-width": (window.innerWidth <= 900 ? subCategories.length*83 : "auto")}}>
                {
                    (subCategories || []).map((val, i) => {
                        return <div
                            className={`sub_bars cursor-pointer d-lg-flex align-items-center justify-content-center justify-content-md-start border-bottom ${val.category_slug == activeSubCategory ? "active_sideBar" : ''}`}
                            onClick={() => updateCategoryProductList(val.category_slug)}
                            key={i}>
                            <img src={val.icon ? val.icon : productImage}
                                alt="product"
                                className="sidebar_image mx-3"
                            />
                            <p className="m-0 cat_name me-1 d-flex align-items-center font_basic">{val.category_name === "Pluggers&Spreader" ? "Pluggers & Spreader" : val.category_name}</p>
                        </div>
                    })
                }
                </div>
            </div>
        </>
    )
}

export default LeftSubCategories