
const Loader = (props) => {
  return <div className="loader_main" style={{bottom : props.bottom, right: props.right}}>
          <div className="spinner-grow text-secondary" role="status" style={{ height: "22px", width: "22px" }}>
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow text-secondary mx-1" role="status" style={{ height: "20px", width: "20px" }}>
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow text-secondary ms-1" role="status" style={{ height: "18px", width: "18px" }}>
            <span className="visually-hidden">Loading...</span>
          </div>
          <style jsx>
          {`
            .loader_main{
              position:absolute;
              z-index:1000000;
              
            }
          `}
          </style>
        </div>
}

export default Loader;