import React,{useState, useEffect} from "react";
import {useParams} from "react-router-dom"
import { Header } from "../shared-components";
import {orderDetails} from "../../utils/apiUrlsSetup"
import Loader from "../shared-components/loader/loader";
import "./returnItemPage.css"
import OrderCard from "../shared-components/orderCard/orderCard";
import ItemCard from "../shared-components/itemCard/itemCard";

const ReturnItemPage = () =>{

    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {} ;
    const {id} = useParams()

    const [showLoader, setShowLoader] = useState(false)
    const [itemsData, setItemsData] = useState([])

    useEffect(()=>{
        if(id){
            getOrderItemsList()
        }
    },[])

    const getOrderItemsList = async() =>{
        setShowLoader(true)
        const formData = new FormData()
        formData.append("order_id", id)
        const data = await orderDetails(formData)
        if(data.ErrorCode === 0 ){
            setShowLoader(false)
            setItemsData(data.Response)
        }
    }
    const sortedReturnItems = itemsData?.returnItem?.sort(function(a, b) {
        return a?.id - b?.id;
    });
    let combineReturnedItems  = [];
    
    for(let i=0;i<sortedReturnItems?.length;i++){
        if(sortedReturnItems[i]?.id === sortedReturnItems[i-1]?.id){
            combineReturnedItems[i-1] = {
                ...sortedReturnItems[i-1],
                return_qty:( sortedReturnItems[i-1]?.return_qty || 0)  + (sortedReturnItems[i]?.return_qty || 0)    
            }
        }else{
            combineReturnedItems.push(sortedReturnItems[i]);
        }
    }

    return(
        <div className="return_item_page mb-5 pb-5">
            {showLoader ? <Loader right="50%" bottom="47%" /> : null}
            <Header
                userDetails={userDetails} 
            />
            <div className="row m-0 mx-3 mx-lg-0 items_list_main">
                <div className="col-0 col-lg-2"></div>
                <div className="col-12 col-lg-8 mt-3 rounded p-3 px-2 px-md-4 center_content_div">
                    <p className="fs-5 font_500 border-bottom">Items List :</p>
                    <div className="order_details my-3">
                        <p className="m-0 text-secondary">Order Id : {itemsData.order_number}</p>
                        <p className="m-0 text-secondary">Order Date : {itemsData.order_date}</p>
                        <p className="m-0 text-secondary">Order Status : {itemsData.order_status}</p>
                        <p className="m-0 text-secondary">Payment Mode : {itemsData.payment_mode}</p>
                    </div>
                    <ItemCard 
                        ItemsList={itemsData.items}
                        returnBtn={true}
                        orderNumber={itemsData.order_number}  
                        combineReturnedItems={combineReturnedItems}  
                     />

                    {itemsData.returnItem && itemsData.returnItem.length ?
                    
                    <>
                        <p className="fs-5 font_500 border-bottom mt-3">Returned Items List :</p>
                        <ItemCard 
                            ItemsList={itemsData.returnItem} 
                            returnBtn={false}
                            orderNumber={itemsData.order_number}
                        />
                    </>
                    :null}

                </div>
                <div className="col-0 col-lg-2"></div>
            </div>
        </div>
    )
}

export default ReturnItemPage ;