import React, { useState, useEffect } from "react"
import { Header } from "../shared-components";
import { orderItemDetails, getRequestQuestion, getProductQuestion, returnOrder, orderDetails } from "../../utils/apiUrlsSetup"
import { useParams, useLocation, Link } from "react-router-dom";
import Popup from 'reactjs-popup';
import {toast} from "react-toastify"
import 'reactjs-popup/dist/index.css';
import "./returnRefundPage.css"
import Loader from "../shared-components/loader/loader";

const ReturnRefundPage = () => {

  const { id } = useParams()
  const location = useLocation()
  const orderId = location.state

  const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

  const [itemDetailData, setItemDetailData] = useState([]);
  const [returnReason, setReturnReason] = useState('');
  const [reasonToReturn, setReasonToReturn] = useState([])
  const [counter, setCounter] = useState(0)
  const [arr, setArr] = useState([])
  const [category_check_list, setCategoryCheckList] = useState([])
  const [productQuestion, setProductQuestion] = useState([])
  const [file, setFile] = useState([])
  const [image_1, setImage1] = useState();
  const [image_2, setImage2] = useState();
  const [image_3, setImage3] = useState();
  const [image_4, setImage4] = useState();
  const [image_5, setImage5] = useState();
  const [srNumber, setSRNumber] = useState('')
  const [returnType, setReturnType] = useState();
  const [showLoader, setShowLoader] = useState(false)

  const [confirmRequestModal, setConfirmRequestModal] = useState(false)
  const [confirmRequestDetails, setConfirmRequestDetails] = useState('');
  const [cancelOrderBox, setCancelOrderBox] = useState(false);


  const selectOption = [
    {
      name: "Initiate Return",
      value: "Initiate Return",
      id: 1,
    },
    {
      name: "Initiate Replacement",
      value: "Initiate Replacement",
      id: 2,
    },
  ];


  useEffect(() => {
    if (orderId && id) {
      getOrderItemDetails()
    }
  }, [])

  useEffect(() => {
    requesQuestionList()

  }, [])

  const handleClick = () => {
    let element = document.getElementById("images");
    element.click();
  };

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }

  const decrement = (totalCount) => {
    if (counter + totalCount <= 1) {
      setCounter(counter);
    } else {
      setCounter(counter - 1);
    }
  };

  const increment = (totalCount) => {
    if (counter + totalCount >= totalCount) {
      setCounter(counter);
    } else {
      setCounter(counter + 1);
    }
  };

  const requesQuestionList = async () => {
    const data = await getRequestQuestion({})
    if (data.ErrorCode === 0) {
      setReasonToReturn(data.Response)
      getcurrentProductQuestion()
    }
  }

  const getcurrentProductQuestion = async () => {
    const formData = new FormData()
    formData.append("item_id", id)
    formData.append("type", 1)
    const data = await getProductQuestion(formData)
    if (data?.ErrorCode === 0) {
      setProductQuestion(data?.Response);
    }
  }

  const getReturnItemDetails = (orderItems, orderItemDetail) =>{
    const sortedReturnItems = orderItems?.returnItem?.sort(function (a, b) {
      return a?.id - b?.id;
    });
    let combineReturnedItems = [];

    for (let i = 0; i < sortedReturnItems?.length; i++) {
      if (sortedReturnItems[i]?.id === sortedReturnItems[i - 1]?.id) {
        combineReturnedItems[i - 1] = {
          ...sortedReturnItems[i - 1],
          return_qty:
            (sortedReturnItems[i - 1]?.return_qty || 0) +
            (sortedReturnItems[i]?.return_qty || 0),
        };
      } else {
        combineReturnedItems.push(sortedReturnItems[i]);
      }
    }
    const item = combineReturnedItems.find((i)=>i?.id===orderItemDetail?.item_id)
    return item;
  }

  const getOrderItemDetails = async () => {
    const formData = new FormData()
    formData.append("order_id", orderId)
    formData.append("item_id", id)

    const data = await orderItemDetails(formData)
    const orderItems = await orderDetails(formData)
   
    if (data.ErrorCode === 0) {
     const item =  getReturnItemDetails(orderItems?.Response, data.Response)
      setItemDetailData({...data.Response, return_qty:(item?.return_qty || 0)})
    }
  }

  let newObject = Object.values(arr);
  let finalArr = newObject.filter((value) => Object.keys(value).length !== 0);

  const handleReturnRequest = async () => {
    setShowLoader(true)
    
    var form_data = new FormData();

    form_data.append("order_id",orderId)
    form_data.append("quantity", counter + (parseInt(itemDetailData["quantity"])- parseInt(itemDetailData["return_qty"])))
    form_data.append("return_type", returnType)
    form_data.append("reason", returnReason)
    form_data.append("item_id", parseInt(id))
    form_data.append( "question_response", JSON.stringify(finalArr))
    form_data.append("image1", image_1)
    form_data.append("image2", image_2)
    form_data.append("image3", image_3)

    const data = await returnOrder(form_data);
    setShowLoader(false)
    if (data?.ErrorCode === 102 || data?.ErrorCode === 103) {
      setConfirmRequestModal(true);
      setConfirmRequestDetails(data.ErrorMessage);
      setSRNumber(data?.Response?.request_no);
    }
    if(data?.ErrorMessage==='success'){
      toast.error(data?.Response)
    }
  };

  const handleReasontoReturn = (e, index, totalElement, element) => {
    setReturnReason(e?.target?.value);

    let obj = {};
    obj["id"] = element["id"];
    obj["type"] = element["type"];
    obj["question_option"] = e?.target?.value;
  };

  const handleInputChange = (e, element, index) => {
    if (element?.question_type_id === 2) {

      let newArray = [...category_check_list, e.target.value];
      if (category_check_list.includes(e.target.value)) {
        newArray = newArray.filter((day) => day !== e.target.value);
      }
      setCategoryCheckList(newArray);
    }
  };
  const handleBlur = (e, element) => {
    let obj = {};

    obj = {
      question_id: element?.id,
      question_type_id: element?.question_type_id,
      answer:
        element?.question_type_id === 2
          ? category_check_list
          : e?.target?.value,
    };

    let updatedCarsArray = { ...arr, [e?.target?.name]: obj };

    setArr(updatedCarsArray);
  };

  function uploadSingleFile(e) {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);

    let images = []
    let images1 = []

    for (let i = 0; i < e.target.files.length; i++) {
      images1.push(URL.createObjectURL(e.target.files[i]));
      images.push(e.target.files[i]);
      if (i == 0) {
        setImage1(e.target.files[i]);
      }
      if (i == 1) {
        setImage2(e.target.files[i]);
      }
      if (i == 2) {
        setImage3(e.target.files[i]);
      }
      if (i == 3) {
        setImage4(e.target.files[i]);
      }
      if (i == 4) {
        setImage5(e.target.files[i]);
      }
    }

    setFile(file.concat(images1));
  }

  const handleChange = (e) => {
    setReturnType(e);
  };

  // console.log(typeof(confirmRequestDetails))
  // console.log(typeof(srNumber))



  return (
    <div className="return_refund_page mb-5">
      { showLoader ? <Loader right="50%" bottom="47%" /> : null}
      <Header
        userDetails={userDetails}
      />
      <div className="row m-0 items_list_main mx-3 mx-lg-0">
        <div className="col-0 col-lg-2"></div>
        <div className="col-12 col-lg-8 mt-3 rounded p-3 px-4 center_content_div mb-5">
          <div className="order_details my-3">
            <p className="m-0 text-secondary mb-1">Product Name : {itemDetailData.product_name}</p>
            <p className="m-0 text-secondary mb-1">Order Id : {itemDetailData.order_number}</p>
            <p className="m-0 text-secondary mb-1">Order Date : {itemDetailData.order_date}</p>
            <p className="m-0 text-secondary mb-1">Order Amount : {itemDetailData.total}</p>
            <p className="m-0 text-secondary">Quantity : {itemDetailData.quantity}</p>
          </div>
          <p className="m-0 font_500">Please Select the Quantity to place Return/Replacement.</p>
          <div className="edit_quantity d-flex justify-content-between my-2">
            <p className="m-0 text-secondary">Choose Item Quantity</p>

            <div className="right w-1/5">
              <div className="order-item flex justify-around">
                <span
                  className="cursor-pointer self-center"
                  onClick={() => decrement(Number(itemDetailData["quantity"] - itemDetailData["return_qty"]))}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M20 12H4"
                    />
                  </svg>
                </span>
                {Number(counter) + (Number(itemDetailData["quantity"] - itemDetailData["return_qty"]))}
                <span
                  className="cursor-pointer self-center"
                  onClick={() => {
                    increment(Number(itemDetailData["quantity"] - itemDetailData["return_qty"]));
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </span>
              </div>
            </div>

          </div>
          <div className="order-quantity flex justify-between">
            <div className="left w-3/4">
              <div className="order-item flex flex-col">
                {reasonToReturn && reasonToReturn.map((element, index) => (
                  <>
                    <label>{element["question"]}</label>
                    <div className="px-4 shadow-primary rounded-full w-md:6/12 w-sm:full my-3">
                      <select
                        className="my-3 w-full bg-white  placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                        onChange={(e) => {
                          handleReasontoReturn(
                            e,
                            index + 1,
                            reasonToReturn.length,
                            element
                          );
                        }}
                      >
                        <option value="select">Select</option>
                        {element["question_option"].map((x) => (
                          <>
                            <option value={x["question"]}>
                              {x["question"]}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="order-quantity flex justify-between">
            <div className="left w-full">
              <div className="order-item flex flex-col">
                {productQuestion.map((element, index) => (
                  <>
                    <label>{element["question"]}</label>
                    <div className="pl-4  my-3 flex justify-around flex-col md:flex-row">
                      {element["question_type_id"] === 1 ? (
                        <>
                          {element["question_option"].map((x, i) => (
                            <div className=" flex mt-2">
                              <div className="radio flex items-center">
                                <input
                                  id={`radio${i}-${index + 1}`}
                                  name={`radio${index}`}
                                  type="radio"
                                  value={x["options"]}
                                  className="cursor-pointer mr-2"
                                  onChange={(e) => {
                                    handleInputChange(e, element, index);
                                    handleBlur(e, element);
                                  }}
                                />
                                <label
                                  htmlFor={`radio${i}-${index + 1}`}
                                  className="radio-label cursor-pointer"
                                >
                                  {" "}
                                  {x["options"]}
                                </label>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      {element["question_type_id"] === 2 ? (
                        <>
                          {element["question_option"].map((x, index) => (
                            <div className="w-full flex  mt-2">
                              <div className="checkbox flex items-center">
                                <input
                                  id={`checkbox-${index + 1}`}
                                  name="checkbox"
                                  type="checkbox"
                                  value={x["options"]}
                                  // checked = {checkboxObj[index] || false}
                                  className="cursor-pointer mr-2"
                                  onChange={(e) => {
                                    handleInputChange(e, element, index);
                                  }}
                                  onBlur={(e) => {
                                    handleBlur(e, element);
                                  }}
                                />
                                <label
                                  htmlFor={`checbox-${index + 1}`}
                                  className="checkbox-label cursor-pointer"
                                >
                                  {" "}
                                  {x["options"]}
                                </label>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      {element["question_type_id"] === 3 ? (
                        <>
                          <textarea
                            onChange={(e) => {
                              handleInputChange(e, element, index);
                            }}
                            onClick={(e) => {
                              handleBlur(e, element);
                            }}
                            name="textbox"
                            className="my-3 w-full  p-4 bg-white rounded-sm  shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                          ></textarea>
                        </>
                      ) : null}
                      {element["question_type_id"] === 4 ? (
                        <select
                          className="my-3 w-full bg-white  placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                          onChange={(e) => {
                            handleReasontoReturn(
                              e,
                              index + 1,
                              productQuestion.length,
                              element
                            );
                          }}
                        >
                          <option value="select">Select</option>
                          {element["question_option"].map((x) => (
                            <>
                              <option value={x["options"]}>
                                {x["options"]}
                              </option>
                            </>
                          ))}
                        </select>
                      ) : null}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="return-replacement mt-6 text-center text-xs sm:text-sm">
            {/* <div className=" flex">
            <input 
              type="checkbox"
              className="cursor-pointer mr-2"
              checked 
              onClick={()=>{handleChange(1)}} 
              disabled
            />
            <label
              htmlFor={`radioReturn-${1}`}
              className="radio-label cursor-pointer"
            >
              Initiate Return
            </label>
          </div> */}

            <h5 className="text-start">Select an Action</h5>
            <div className="choose-option flex justify-around mt-3">
              {selectOption.map((item, index) => (
                <>
                  <div className="w-1/2">
                    <div className="radio flex align-center items-center">
                      <input
                        id={`radioReturn-${index + 1}`}
                        name="radio"
                        type="radio"
                        onChange={() => handleChange(item.id)}
                        value={item.value}
                        className="cursor-pointer mr-2"
                      />
                      <label
                        htmlFor={`radioReturn-${index + 1}`}
                        className="radio-label cursor-pointer"
                      >
                        {" "}
                        {item.name}
                      </label>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div>
              <div className="form-group mt-5">
                <p className="text-left text-md font-bold mb-2">
                  Upload upto 5 images{" "}
                </p>
                <div className="flex flex-wrap">
                  <input
                    type="file"
                    multiple
                    id="images"
                    className="form-control hidden"
                    onChange={uploadSingleFile}
                    hidden
                  />
                  <span
                    className="w-28 h-28 sm:w-40 sm:h-40 border-4 mr-2 border-pink-600 flex items-center justify-center cursor-pointer"
                    onClick={handleClick}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </span>

                  {file.length > 0 &&
                    file.map((item, index) => {
                      return (
                        <div
                          key={item}
                          className="relative mr-2 w-28 h-28 sm:w-40 sm:h-40  mb-3"
                        >
                          <img
                            src={item}
                            alt=""
                            className="w-28 h-28 sm:w-40 sm:h-40 border-4 border-pink-600"
                          />
                          <button
                            type="button"
                            onClick={() => deleteFile(index)}
                            className="transform rotate-45 absolute -top-2 -right-1 sm:right bg-pink-200 rounded-full p-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 4v16m8-8H4"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                </div>
              </div>
              <button
                className={` ${!returnType || !returnReason || file.length === 0
                    ? "disabled:opacity-50"
                    : ""
                  } w-3/6 w-md:2/6  rounded-full bg_green text-md h-11 my-3 text-white cursor-pointer`}
                disabled={file.length === 0}
                onClick={handleReturnRequest}
              >
                CONFIRM
              </button>
            </div>
          </div>


        </div>
        <div className="col-0 col-lg-2"></div>
      </div>
      <div className="d-flex justify-content-center align-items-center"></div>
      {confirmRequestModal ? (
        <Popup
          closeOnDocumentClick={false}
          className="modal_main_style"
          onClose={() => setConfirmRequestModal(false)}
          open
          modal
        >
          {(close) => (
            <div className="modal d-block position-absolute">
              <div className="border-b mx-3 my-2 py-1 font-bold text-base border-black flex justify-between items-center">
                Confirmation Status
              </div>
              <div className="content mx-2 my-2">
                <p className=" m-0 mb-1 text-center">
                  {" "}
                  {confirmRequestDetails}
                </p>
                <p className="text-center">Request SR Number : 
                {`  ${srNumber}`}
                </p>
                <div className="button-list flex  justify-around mt-4 pt-1">
                  <Link
                    to={{
                      pathname: `/account/order`,
                      state: {
                        orderId: orderId,
                        return: true,
                      },
                    }}
                    className="contact-support rounded-full bg-white text-xs py-2 px-5 py-md-3  text-center font-bold shadow-secondary text-primary"
                  >
                    Back
                  </Link>
                  <button className="contact-support rounded-full bg-white text-xs py-2 px-2 py-md-3 text-center font-bold shadow-secondary text-primary">
                    <Link to="/">Continue Shopping</Link>
                  </button>
                </div>
              </div>
            </div>
          )}
        </Popup>
      ) : null}
      {
        <style>
          {`
          
          {/* body{
            overflow: ${confirmRequestModal ? 'hidden' : ''}
          } */}

          `}
        </style>
      }
    </div>
  )
}

export default ReturnRefundPage;